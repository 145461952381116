import styled from "styled-components"

export const Form = styled.form`
  width: var(--width, 100%);
  margin: var(--margin, 0);
`

export const Input = styled.input`
  --border-bottom-color: transparent;
  width: 100%;
  padding: 1rem 1rem;
  border-bottom: 3px solid var(--border-bottom-color);
  color: inherit;
  font-family: inherit;
  font-size: var(--font-size, calc(var(--font-size-default) * 0.8));
  outline: none;
  
  &::placeholder {
    opacity: 0.5;
  }
  &:focus {
    --border-bottom-color: var(--color-primary);
    box-shadow: 0 1rem 4rem rgba(0,0,0,0.1);
  }
`