import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { handleLogin, isLoggedIn } from 'services/auth.js'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import { Button } from 'components/Shared/_Button'
import { Form, Input } from 'components/Shared/_Form'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.div`
  --width: 90%;
  ${p => p.theme.mq.xs`
    --width: 80%;
  `}
  ${p => p.theme.mq.sm`
    --width: 60%;
  `}
  ${p => p.theme.mq.md`
    --width: 50%;
  `}
  ${p => p.theme.mq.lg`
    --width: 40%;
  `}
  width: var(--width);
  margin: calc(var(--margin-default) * 2.5) auto calc(var(--margin-default) * 4);
  border-radius: 2rem;
  background-color: var(--color-beige);
  box-shadow: var(--box-shadow);

  h2 {
    margin-top: calc(var(--margin-default) * 1.5);
    margin-bottom: var(--margin-default);
    opacity: 0.9;
  }

  button {
    margin: var(--margin-default) 0;
    width: 80%;
  }

  .form {
    --width: 70%;
    --margin: var(--margin-default) auto;
    margin-bottom: calc(var(--margin-default) * 1.5);

    label {
      display: inline-block;
      width: 100%;
      text-align: left;
      color: var(--color-primary);
      margin-bottom: 1rem;
    }
  }
`
//#endregion

const LoginPage = ({ location }) => {
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleUpdate = e => {
    // e.persist()
    setError('')
    setPassword(e.target.value)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const user = await handleLogin(password)
    if (user) return navigate('/member')
    setError('Invalid Credential')
  }

  useEffect(() => {
    if (isLoggedIn()) {
      return navigate(`/member`)
    }
  }, [location])

  useEffect(() => {
    if (!error) return
    const timer = setTimeout(() => {
      setError('')
    }, 3000)
    return () => {
      clearTimeout(timer)
    }
  }, [error])

  return (
    <PageContent>
      <Seo title='Login' />
      <Wrapper>
        <h2>登入成員專區</h2>

        {error && <h4>{error}</h4>}

        <Form onSubmit={handleSubmit} className='form'>
          <label htmlFor='password'>忘記密碼者請聯絡晶晶行政老師</label>
          <Input
            type='password'
            name='password'
            placeholder='輸入密碼'
            onChange={handleUpdate}
          />
          <Button type='submit'>登入</Button>
        </Form>
      </Wrapper>
    </PageContent>
  )
}

export default LoginPage
